import React, { useState, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import TextAreaAutoComplete from './TextareaAutoComplete';
import useMember from 'hooks/useMember';

interface TextboxContainer {
  width?: string;
  isDisabled?: boolean;
  margin?: string;
}

interface TextareaContainerOptions {
  /** 글자수 제한 사용여부 */
  isCount?: boolean;
  /** 유효성 검사 값 */
  isValid?: boolean;
}

interface TextareaBoxProps {
  [x: string]: any;
  /** 글자수 제한 사용여부 */
  isCount?: boolean;
  /** 최대 글자수 */
  maxLength?: number;
  /** 유효성 검사 값 */
  isValid?: boolean;
  /** 값 변경시 */
  onChange: (e: any) => void;
  /** */
  isDisabled?: boolean;
  /** 최대 높이 */
  maxRows?: number;
  /** autocomplete */
  variables?: any;
  /** 마진 값 */
  margin?: string;
  /** 변수 자동완성  */
  isVariableAutoComplete?: boolean;
}

/** 텍스트 박스 컨테이너 */
const Container = styled.div<TextboxContainer>`
  border-radius: 4px;
  position: relative;
  width: ${(props) => props.width};
  background-color: ${(props) => (props.isDisabled ? 'rgb(242, 242, 242)' : 'rgb(255, 255, 255)')};
  margin: ${(props) => props.margin ?? '0 0 0 0'};
`;

/** 텍스트박스 감싸는 컨테이너 */
const TextareaContainer = styled.div<TextareaContainerOptions>`
  width: 100%;
  box-sizing: border-box;
  padding: ${(props) => (props.isCount ? '10px 10px calc(24px)' : '10px')};
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.isValid ? 'rgb(229, 230, 235)' : 'rgb(255, 0, 0)')};
  border-image: initial;

  &:focus-within {
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => (props.isValid ? 'rgb(55, 125, 255)' : 'rgb(255, 0, 0)')};
    border-image: initial;
  }
`;

/** 텍스트박스 */
const Textarea = styled(TextareaAutosize)`
  position: relative;
  width: 100%;
  min-height: 70px;
  box-sizing: border-box;
  font-size: 13px;
  color: var(--black);
  outline: none;
  border: none;
  resize: none;
`;

/** 글자수 카운트 */
const CountLangs = styled.span`
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  font-size: 11px;
  color: rgb(148, 148, 148);
  position: absolute;
  height: 20px;
  width: 84px;
  left: calc((100% - 84px) - 10px);
  bottom: 7px;
`;

const TextareaBox: React.FC<TextareaBoxProps> = ({
  onChange,
  isCount,
  maxLength,
  isValid = true,
  isDisabled,
  margin,
  width,
  maxRows = 5,
  variables,
  ...props
}) => {
  /** 멤버 */
  const member = useMember();

  const [value, setValue] = useState('');
  const [valueLen, setValueLen] = useState(0);
  /**
   * onChange
   * @param e
   */
  const handleChange = (e: any) => {
    if (isCount && maxLength && e.target.value.length > maxLength) return;
    setValue(e.target.value);
    setValueLen(e.target.value.length);
    onChange(e);
  };

  useEffect(() => {
    setValue(props.value);
    setValueLen(props.value?.length);
  }, [props.value]);

  /** TextAreaAutoComplete에 들어갈 list세팅  */
  const [variableList, setVariableList] = useState([{ name: '', char: '' }]);
  let variableInfo: any[] = [];
  useEffect(() => {
    variableList?.shift();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    variableInfo = variables?.map((item: any, idx: number) => {
      if (!member.config?.blockLevel || member.config?.blockLevel >= 3) {
        variableList?.push({
          name: `${item.name}`,
          char: `${item.key}`,
        });
      } else {
        /**Auth블럭 숨김 처리시 인증정보도 숨김처리 필요  */
        if (item.group !== 'info') {
          variableList?.push({
            name: `${item.name}`,
            char: `${item.key}`,
          });
        }
      }
    });
    setVariableList(variableInfo);
  }, []);

  return (
    <>
      {variables ? (
        <Container width={width} isDisabled={isDisabled} margin={margin}>
          <TextareaContainer isCount={isCount} isValid={isValid}>
            <TextAreaAutoComplete
              value={value}
              onChange={handleChange}
              disabled={isDisabled}
              variables={variableList}
              {...props}
            />
            {isCount && (
              <CountLangs>
                {valueLen} / {maxLength}
              </CountLangs>
            )}
          </TextareaContainer>
        </Container>
      ) : (
        <Container width={width} isDisabled={isDisabled} margin={margin}>
          <TextareaContainer isCount={isCount} isValid={isValid}>
            <span className="border" />
            <Textarea value={value} onChange={handleChange} disabled={isDisabled} maxRows={maxRows} {...props} />
            {isCount && (
              <CountLangs>
                {valueLen} / {maxLength}
              </CountLangs>
            )}
          </TextareaContainer>
        </Container>
      )}
    </>
  );
};

export default React.memo(TextareaBox);
