import React, { useCallback } from 'react';

import { FileRejection, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Upload } from '../../constants/Icons';
import { Box } from '../Renewal/Components';
import { toast } from 'react-toastify';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#5988fe';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eaf0fe';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 20px;
  border-width: 2px;
  border-radius: 5px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #f8faff;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: calc(100% - 30px);
  width: 100%;

  :hover {
    border-color: #5988fe;
    cursor: pointer;
  }
`;

const Btn = styled.button`
  width: 100%;
  border: none;
  cursor: pointer;
  color: #9ba9be;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: transparent;

  span {
    margin: 5px;
  }
`;

interface FileDropProps {
  dropFiles: File[] | undefined;
  setDropFiles: (files: File[]) => void;
  acceptFile?: string;
  maxFiles?: number;
  useDialog?: boolean;
  useIcon?: boolean;
  showFile?: boolean;
  dropText?: string | undefined;
  initFileName?: string | undefined;
  notifyText?: { acceptError: string; maxFileError: string };

  [key: string]: any;
}

const FileDrop: React.FC<FileDropProps> = ({
  dropFiles,
  setDropFiles,
  acceptFile,
  maxFiles,
  showFile = false,
  useDialog = false,
  useIcon = false,
  dropText = '',
  initFileName = '',
  notifyText,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFile: FileRejection[]) => {
      if (rejectedFile.length > (maxFiles || 1)) {
        if (notifyText) toast.error(notifyText.maxFileError || '', { autoClose: 4000 });
        return;
      }
      if (!acceptedFiles || !acceptedFiles.length) {
        if (notifyText) toast.error(notifyText.acceptError || '', { autoClose: 4000 });
        return;
      }
      setDropFiles(acceptedFiles);
    },
    [setDropFiles],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open: DialogOpen,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    maxFiles: maxFiles ? maxFiles : 1,
    accept: acceptFile ? acceptFile : '',
    useFsAccessApi: false,
  });
  return (
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })} onClick={DialogOpen}>
      <input {...getInputProps()} />
      <>
        {useDialog && dropFiles && (
          <Btn>
            <Box display={'inline-flex'} gap={'8px'} alignItems={'center'}>
              {useIcon && <Upload fill={'var(--secondary-black)'} />}
              {dropText ? dropText : '업로드 할 파일을 선택 해주세요.(또는 해당 영역으로 드래그 해주세요)'}
            </Box>
            {showFile && (
              <span
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal',
                  wordBreak: 'break-all',
                  wordWrap: 'break-word',
                }}
              >
                {initFileName ? initFileName : dropFiles[0]?.name}
              </span>
            )}
          </Btn>
        )}
      </>
    </Container>
  );
};

export default React.memo(FileDrop);
