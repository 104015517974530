import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactTextareaAutoComplete, { TriggerType } from '@webscopeio/react-textarea-autocomplete';
import '@webscopeio/react-textarea-autocomplete/style.css';
import styled from 'styled-components';

interface TextareaAutoCompleteProps {
  [x: string]: any;
  /** 글자수 제한 사용여부 */
  isCount?: boolean;
  /** 최대 글자수 */
  maxLength?: number;
  /** 유효성 검사 값 */
  isValid?: boolean;
  /** 값 변경시 */
  onChange: (e: any) => void;
  /** */
  isDisabled?: boolean;
  /**고객정보 */
  variables?: any;

  /** 활성 유무 */
  isVariableAutoComplete?: boolean;
}

const defaultStyle: React.CSSProperties = {
  position: 'relative',
  width: '100%',
  minHeight: '100px',
  height: 'auto',
  boxSizing: 'border-box',
  fontSize: '13px',
  outline: 'none',
  border: 'none',
  resize: 'vertical',
};

const listStyle: React.CSSProperties = {
  marginBlock: '10px',
  width: '150px',
  maxHeight: '240px',
  overflow: 'auto',
};

const itemStyle: React.CSSProperties = {
  height: '100%',
  border: 'none',
  lineHeight: '20px',
  overflow: 'hidden',
};

const containerStyle: React.CSSProperties = {
  width: '100%',
  height: 'auto',
};

const ItemList = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props.selected === true ? '#e9f1fe' : '#fff')};
  color: rgba(0, 0, 0, 0.8);
`;

const Item = ({ selected, entity: { name, char } }: any) => (
  <ItemList selected={selected}>{`${char}(${name})`}</ItemList>
);
const Loading = ({ data }: any) => <div>Loading</div>;

const TextareaAutoComplete: React.FC<TextareaAutoCompleteProps> = (props) => {
  const [variablesOrigin, setVariablesOrigin] = useState<any[]>(props.variables);
  const [variableInfo, setVariableInfo] = useState([{ name: '', char: '' }]);
  const [diffVariable, setDiffVariable] = useState([{ name: '', char: '' }]);

  useEffect(() => {
    setVariablesOrigin(props.variables);
    setVariableInfo(props.variables);
    setDiffVariable([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeVariableInfo = useCallback(
    (token: any) => {
      for (let i = 0; i < variableInfo.length; i++) {
        if (
          variableInfo[i].char.toLocaleLowerCase().indexOf(token) !== -1 ||
          variableInfo[i].name.toLocaleLowerCase().indexOf(token) !== -1
        ) {
          diffVariable?.push({
            name: variableInfo[i].name,
            char: variableInfo[i].char,
          });
        }
      }
      return diffVariable;
    },
    [diffVariable, variableInfo],
  );

  const handleVariableInfo = (token: any) => {
    setDiffVariable([]);
    setVariableInfo(variablesOrigin);
    const result = changeVariableInfo(token);
    return result;
  };

  const styles = {
    defaultStyle: defaultStyle,
    listStyle: listStyle,
    itemStyle: itemStyle,
    containerStyle: containerStyle,
  };

  const triggerList = useMemo(() => {
    return !props.isVariableAutoComplete
      ? ({} as TriggerType<string | object>)
      : ({
          '#': {
            dataProvider: (token: any) => {
              const result = handleVariableInfo(token);
              console.log({ result });
              return result; //.slice(0, 10);
            },
            component: Item,
            output: (item: any, trigger: any) => `{{${item.char}}}`,
          },
        } as TriggerType<string | object>);
  }, [props]);

  return (
    <>
      <ReactTextareaAutoComplete
        {...props}
        value={props.value}
        onChange={props.onChange}
        loadingComponent={Loading}
        ref={(rta) => {
          rta = rta;
        }}
        style={props.defaultStyle ? props.defaultStyle : styles.defaultStyle}
        containerStyle={props.containerStyle ? props.containerStyle : styles.containerStyle}
        listStyle={props.listStyle ? props.listStyle : styles.listStyle}
        itemStyle={props.itemStyle ? props.itemStyle : styles.itemStyle}
        dropdownStyle={{
          zIndex: 10000000,
        }}
        //onItemHighlighted={(event:any) => {console.log(event)}}
        //onItemSelected={(event:any)=> {console.log(event)}}
        minChar={0}
        trigger={triggerList}
      />
    </>
  );
};

export default React.memo(TextareaAutoComplete);
